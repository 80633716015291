import { createSelector } from 'reselect'

import { createAsyncSelector } from '../AsyncSelector/createAsyncSelector'
import { selectOrganization } from '../data/selectOrganization'
import { client, gql } from '../graphql/client'
import i18n, { keys } from '../i18n'
import { RootStore } from '../redux/types'
import {
  indexArray,
  indexArrayMappedKey,
  indexMultiArray,
} from '../util/indexArray'
import { NoteWithTemplate } from '../vvapi/models'
import {
  makeDateFilter,
  makeFormTypeFilter,
  makeProjectTypeFilter,
  makeSearchTermFilter,
} from './notesFilters'
import { Project } from '../graphql/types'

export const getNotesFilter = (state: RootStore) => {
  return state.notes.filterInfo
}

export const getSelectedProjects = (state: RootStore) => {
  return state.notes.selectedProjectIds
}

const { selector: selectListProjects, refresh: refreshListProjects } =
  createAsyncSelector({
    resource: 'me.organization.projects',
    inputs: {
      isLoggedIn: (state: RootStore) => state.login.isLoggedIn,
      organization: selectOrganization,
    },
    fetcher: async ({ isLoggedIn, organization }, skip) => {
      if (!isLoggedIn || !organization) {
        skip()
        return undefined
      }

      const { projects, generalProjectCount } = await client.request<{
        projects: Project[]
        generalProjectCount: {
          aggregate: {
            count: number
          }
        }
      }>({
        query: gql`
          query GET_ORGANIZATION_PROJECTS($organizationId: Int!) {
            projects: View_ProjectWithBounds(
              where: {
                _and: {
                  organizationId: { _eq: $organizationId }
                  type: { _eq: "notes" }
                  deletedAt: { _is_null: true }
                }
              }
              order_by: { lastTouchedTime: desc }
            ) {
              id
              name
              bounds
              Notes_aggregate(where: { deletedAt: { _is_null: true } }) {
                aggregate {
                  count
                }
              }
            }
            generalProjectCount: Note_aggregate(
              where: {
                _and: {
                  deletedAt: { _is_null: true }
                  organizationId: { _eq: $organizationId }
                  projectId: { _is_null: true }
                }
              }
            ) {
              aggregate {
                count
              }
            }
          }
        `,
        variables: {
          organizationId: organization.id,
        },
      })

      return projects.map((project) => {
        if (!project.id)
          return {
            ...project,
            id: 'general',
            name: i18n.t(keys.noteForm.reservedNames.__VV__GENERAL__TEMPLATE__),
            Notes_aggregate: generalProjectCount,
          }
        return project
      })
    },
  })

export { selectListProjects, refreshListProjects }

const { selector: selectListNotes, refresh: refreshListNotes } =
  createAsyncSelector({
    resource: 'me.organization.notes',
    inputs: {
      isLoggedIn: (state: RootStore) => state.login.isLoggedIn,
      organization: selectOrganization,
    },

    fetcher: async ({ isLoggedIn, organization }, skip) => {
      if (!isLoggedIn || !organization) {
        skip()
        return undefined
      }

      const { notes } = await client.request<{ notes: NoteWithTemplate[] }>({
        query: gql`
          query GET_ORGANIZATION_NOTES($organizationId: Int!) {
            notes: View_NoteWithTemplate(
              where: {
                organizationId: { _eq: $organizationId }
                deletedAt: { _is_null: true }
              }
            ) {
              id
              content
              templateId
              templateName
              pinColor
              createdAt
              feature: Feature {
                id
                geometry
              }
              createdBy: CreatedBy {
                email
                firstName
                lastName
              }
              updatedBy: CreatedBy {
                email
                firstName
                lastName
              }
              projectId
              project: Project {
                name
              }
            }
          }
        `,
        variables: {
          organizationId: organization.id,
        },
      })

      return notes
    },
  })

export { selectListNotes, refreshListNotes }

const EMPTY_NOTES: NoteWithTemplate[] = []

export const selectNotes = createSelector(
  selectListNotes,
  (listNotes) => listNotes.data ?? EMPTY_NOTES
)

export const selectProjects = createSelector(
  selectListProjects,
  (listProjects) => listProjects.data ?? []
)

export const selectNotesById = createSelector(selectNotes, (notes) =>
  indexArray(notes, 'id')
)

export const selectProjectsById = createSelector(selectProjects, (projects) =>
  indexArrayMappedKey(projects, (p) => p.id ?? 'general')
)

export const selectNotesByProjectId = createSelector(selectNotes, (notes) => {
  const noteArrayByProjectId = indexMultiArray(notes, 'projectId')
  const notesByProjectId = Object.entries(noteArrayByProjectId).reduce(
    (acc, [projectId, projectNotes]) => {
      if (!projectId || projectId === 'null' || projectId === 'undefined') {
        acc.general = {
          ...(acc.general ?? {}),
          ...(indexArray(projectNotes, 'id') as Record<
            string,
            NoteWithTemplate
          >),
        }
      } else {
        acc[projectId] = indexArray(projectNotes, 'id') as Record<
          string,
          NoteWithTemplate
        >
      }
      return acc
    },
    {} as Record<string, Record<string, NoteWithTemplate>>
  )
  return notesByProjectId
})

export const selectAllPinColorsByTemplateNames = createSelector(
  selectNotes,
  (notes) => {
    const notesMap = new Map<string, string>()

    for (const note of notes) {
      notesMap.set(note.templateName, note.pinColor)
    }

    return notesMap
  }
)

const getEditingNoteId = (state: RootStore) => state.notes.editingNoteId

export const selectEditingNote = createSelector(
  selectNotesById,
  getEditingNoteId,
  (notesById, editingNoteId) => {
    if (!editingNoteId) {
      return undefined
    }

    return notesById[editingNoteId]
  }
)

export const selectFilteredNotes = createSelector(
  getNotesFilter,
  selectNotesById,
  (filter, notes) => {
    const noteExtractor = (id: string) =>
      `${Object.entries(notes[id]?.content)
        .map(([key, value]) => {
          const translatedKey = i18n.t(`noteForm.reservedNames.${key}`, {
            defaultValue: key,
          })

          let translatedValue = ''

          if (Array.isArray(value.value)) {
            translatedValue = value.value
              .map((item: string) =>
                i18n.t(`noteForm.reservedNames.${item}`, {
                  defaultValue: item,
                })
              )
              .join(' ')
          } else {
            translatedValue = i18n.t(`noteForm.reservedNames.${value.value}`, {
              defaultValue: value.value,
            })
          }

          return `${translatedKey} ${translatedValue}`
        })
        .join(' ')} ${notes[id]?.templateName}`

    const searchTermFilter = makeSearchTermFilter(
      filter.searchTerm,
      noteExtractor
    )

    const dateExtractor = (id: string) =>
      notes[id] && new Date(notes[id]!.createdAt!)

    const dateFilter = makeDateFilter(
      dateExtractor,
      filter.fromDate,
      filter.toDate
    )

    const filterTypeExtractor = (id: string) =>
      notes[id] && notes[id]!.templateName

    const formTypeFilter = makeFormTypeFilter(
      filter.formType,
      filterTypeExtractor
    )

    const filterProjectTypeExtractor = (id: string) =>
      notes[id] && (notes[id].projectId ?? 'general')

    const projectTypeFilter = makeProjectTypeFilter(
      filter.projectType,
      filterProjectTypeExtractor
    )

    return Object.keys(notes)
      .filter(
        and(searchTermFilter, dateFilter, formTypeFilter, projectTypeFilter)
      )
      .map(String)
  }
)

export const selectFilteredNotesByProjectId = createSelector(
  selectFilteredNotes,
  selectNotesById,
  (filteredNoteIds, notesById) => {
    const filterNotes = filteredNoteIds.map((id) => notesById[id])
    const noteArrayByProjectId = indexMultiArray(filterNotes, 'projectId')
    const notesByProjectId = Object.entries(noteArrayByProjectId).reduce(
      (acc, [projectId, projectNotes]) => {
        if (!projectId || projectId === 'null' || projectId === 'undefined') {
          acc.general = {
            ...(acc.general ?? {}),
            ...(indexArray(projectNotes, 'id') as Record<
              string,
              NoteWithTemplate
            >),
          }
        } else {
          acc[projectId] = indexArray(projectNotes, 'id') as Record<
            string,
            NoteWithTemplate
          >
        }
        return acc
      },
      {} as Record<string, Record<string, NoteWithTemplate>>
    )
    return notesByProjectId
  }
)

const and = (...funcs: any[]) => {
  return (...innerArgs: any[]) => funcs.every((func) => func(...innerArgs))
}
