import { Info, OpenInBrowser, Save } from '@mui/icons-material'
import { Button, Divider, Stack, Tooltip, Typography } from '@mui/material'
import * as React from 'react'
import {
  MRT_ColumnDef,
  MRT_Table as Table,
  useMaterialReactTable,
} from 'material-react-table'
import { useRateMapContext } from '../../RateMapContext'
import AsyncSelectorStatusOverlay from '../../../../../AsyncSelector/AsyncSelectorStatusOverlay'
import { selectPreferredLanguage } from '../../../../../data/selectPreferredLanguage'
import { useRedux } from '../../../../../hooks/useRedux'
import { ZoneRow } from './RateMapConfig'
import { ExportSelector } from './ExportSelector'
import i18n, { keys } from '../../../../../i18n'
import { MRT_Localization_FR } from 'material-react-table/locales/fr'
import { MRT_Localization_EN } from 'material-react-table/locales/en'

export const FinalOutput = ({
  zones,
  zonesLoading,
}: {
  zones: ZoneRow[]
  zonesLoading: boolean
}) => {
  const { isDirty, save, rateMap, amendmentTypes, unitTypes } =
    useRateMapContext()

  const [state] = useRedux()

  const language = selectPreferredLanguage(state)
  const preferredUnitSystem = state.preferences.preferredUnitSystem
  const currencySymbol = preferredUnitSystem === 'imperial' ? '$' : '€'

  const columns = React.useMemo<
    MRT_ColumnDef<{
      amendment: string
      totalAmount: string
      totalCost: string
    }>[]
  >(
    () => [
      {
        header: i18n.t(keys.rateMapAmendmentTypeTitle),
        accessorKey: 'amendment',
        size: 92,
      },
      {
        header: i18n.t(keys.rateMapTotalApplicationAmountTitle),
        accessorKey: 'totalAmount',
      },
      { header: i18n.t(keys.rateMapCostTitle), accessorKey: 'totalCost' },
    ],
    []
  )
  const data = React.useMemo(() => {
    const amendmentType = rateMap?.amendmentTypeId
      ? amendmentTypes[rateMap.amendmentTypeId]
      : undefined
    const unitType = rateMap?.unitTypeId
      ? unitTypes[rateMap.unitTypeId]
      : undefined

    const totalAmount = zones.reduce((total, zone) => {
      return total + zone.size * zone.rateOfApplication
    }, 0)

    return [
      {
        amendment: amendmentType?.name[language] ?? '',
        totalAmount: `${totalAmount.toFixed(2)} ${
          unitType?.name[preferredUnitSystem]?.[language]?.split('/')[0]
        }`,
        totalCost: `${(Number(rateMap?.unitCost ?? 0) * totalAmount).toFixed(
          2
        )} ${currencySymbol}`,
      },
    ]
  }, [
    zones,
    rateMap,
    amendmentTypes,
    language,
    unitTypes,
    currencySymbol,
    preferredUnitSystem,
  ])
  //column definitions...

  const table = useMaterialReactTable({
    initialState: { density: 'compact' },
    columns,
    data,
    mrtTheme: () => ({
      baseBackgroundColor: '#00000000', //change default background color
    }),

    muiTableBodyRowProps: {
      hover: false,

      sx: { backgroundColor: 'transparent' },
    },
    muiTableProps: {
      sx: {
        border: 'none',
        backgroundColor: 'transparent',
        caption: {
          captionSide: 'top',
        },
      },
    },
    muiTableHeadCellProps: {
      sx: {
        boxShadow: 'none',
        border: 'none',
        backgroundColor: 'transparent',
        textTransform: 'uppercase',
        fontWeight: 'thin',
        fontSize: '10px',
        textWrap: 'pretty',
        textOverflow: 'clip',
      },
    },
    muiTableBodyProps: {
      sx: {
        boxShadow: 'none',
      },
    },
    muiTableBodyCellProps: {
      sx: {
        backgroundColor: 'transparent',
        border: 'none',
      },
    },
    columnFilterDisplayMode: 'popover',
    enableBatchRowSelection: false,

    enableDensityToggle: false,
    enableFilters: false,
    enableGlobalFilter: false,
    enableFullScreenToggle: false,
    enableTableFooter: false,

    enableColumnActions: false,
    enableColumnFilters: false,
    enablePagination: false,
    enableSorting: false,
    localization: language === 'fr' ? MRT_Localization_FR : MRT_Localization_EN,
  })

  const [exportOpen, setExportOpen] = React.useState(false)

  return (
    <Stack gap={1} sx={{ width: '100%' }}>
      <Stack direction="row" gap={1} alignItems="center">
        <Typography
          sx={{
            fontWeight: '700',
            fontSize: '12px',
            letterSpacing: '-3.5%',
          }}
        >
          {i18n.t(keys.rateMapFinalOutputTitle)}
        </Typography>
        <Tooltip title={i18n.t(keys.rateMapFinalOutputDescription)}>
          <Info sx={{ fontSize: '14px' }} />
        </Tooltip>
      </Stack>
      <AsyncSelectorStatusOverlay requests={[]} isLoading={zonesLoading}>
        <Table table={table} />
      </AsyncSelectorStatusOverlay>
      <Divider />
      <Stack
        direction={'row'}
        spacing={2}
        alignItems="center"
        justifyContent={'space-between'}
      >
        <Button
          variant="contained"
          sx={{ width: '100%' }}
          color="inherit"
          disabled={!isDirty}
          onClick={save}
        >
          <Stack
            direction={'row'}
            spacing={2}
            alignItems="center"
            justifyContent="center"
            gap={1}
            sx={{ width: '100%' }}
          >
            {i18n.t(keys.generic.save)}
            <Save />
          </Stack>
        </Button>
        <Button
          variant="contained"
          sx={{ width: '100%' }}
          color="inherit"
          disabled={isDirty}
          onClick={() => setExportOpen(true)}
        >
          <Stack
            direction={'row'}
            spacing={2}
            alignItems="center"
            justifyContent="center"
            gap={1}
            sx={{ width: '100%' }}
          >
            {i18n.t(keys.generic.export)}
            <OpenInBrowser />
          </Stack>
        </Button>
      </Stack>
      <ExportSelector open={exportOpen} onClose={() => setExportOpen(false)} />
    </Stack>
  )
}
