import {
  // Add,
  Info,
} from '@mui/icons-material'
import {
  Box,
  // Button,
  Collapse,
  MenuItem,
  Radio,
  Select,
  Slider,
  Stack,
  styled,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material'
import * as React from 'react'
import i18n, { keys } from '../../../../../i18n'
import { useRateMapContext } from '../../RateMapContext'

const SliderContainer = styled(Box)(({ theme }) => ({
  cursor: 'pointer',
  backgroundColor: '#00000033',
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(1),
  boxSizing: 'border-box',
  border: `1px solid transparent`,
  width: '100%',
}))

const StyledSlider = styled(Slider)(({ theme }) => ({
  '& .MuiSlider-thumb': {
    backgroundColor: theme.palette.text.primary,
    height: 16,
    width: 16,
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&:before': {
      display: 'none',
    },
  },
  '& .MuiSlider-track': {
    border: 'none',
    height: 2,
  },
  '& .MuiSlider-rail': {
    backgroundColor: '#e9e9e9',
    height: 2,
  },
}))

const ZoneContainer = styled(Box)<{ selected?: boolean }>(
  ({ theme, selected }) => ({
    cursor: 'pointer',
    backgroundColor: selected ? '#00000033' : 'transparent',
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
    boxSizing: 'border-box',
    border: `1px solid transparent`,
    width: '100%',
    '&:hover': {
      backgroundColor: '#00000033',
      border: `1px solid ${theme.palette.primary.main}`,
    },
  })
)

export const VigorZoneConfig = () => {
  const theme = useTheme()
  const { rateMap, setModifiedRateMap, amendmentModeTypes } =
    useRateMapContext()

  const handleChangeMode = React.useCallback(
    (mode: 'absolute' | 'relative') => {
      if (!rateMap) {
        return
      }

      const amendmentModeType = amendmentModeTypes[mode]

      if (mode === 'relative' && !rateMap.modeConfiguration) {
        return setModifiedRateMap?.({
          ...rateMap,
          mode,
          modeConfiguration:
            rateMap?.modeConfiguration ??
            amendmentModeType?.defaultConfiguration,
        })
      }

      return setModifiedRateMap?.({ ...rateMap, mode })
    },
    [setModifiedRateMap, rateMap, amendmentModeTypes]
  )

  const handleChangeClassifyBy = React.useCallback(
    (value: 'equal-areas' | 'value-ranges') => {
      if (!rateMap) {
        return
      }
      return setModifiedRateMap?.({
        ...rateMap,
        modeConfiguration: {
          ...(rateMap?.modeConfiguration ?? { classes: 5 }),
          classifyBy: value,
        },
      })
    },
    [setModifiedRateMap, rateMap]
  )

  const handleChangeZones = React.useCallback(
    (classNum: number) => {
      if (!rateMap) {
        return
      }

      const classes = Math.max(classNum, 2)

      return setModifiedRateMap?.({
        ...rateMap,
        modeConfiguration: {
          ...(rateMap?.modeConfiguration ?? { classifyBy: 'equal-areas' }),
          classes,
        },
      })
    },
    [setModifiedRateMap, rateMap]
  )

  return (
    <Stack gap={1} sx={{ width: '100%' }}>
      <Stack direction="row" gap={1} alignItems="center">
        <Typography
          sx={{
            fontWeight: '700',
            fontSize: '12px',
            letterSpacing: '-3.5%',
          }}
        >
          {i18n.t(keys.rateMapVigorZoneTypeTitle)}
        </Typography>
        <Tooltip title={i18n.t(keys.rateMapVigorZoneTypeDescription)}>
          <Info sx={{ fontSize: '14px' }} />
        </Tooltip>
      </Stack>
      <Stack
        direction="row"
        justifyContent="stretch"
        spacing={1}
        sx={{ width: '100%' }}
      >
        <ZoneContainer
          selected={rateMap?.mode === 'absolute'}
          onClick={() => handleChangeMode('absolute')}
        >
          <Stack direction={'row'} spacing={2} alignItems="center">
            <Radio checked={rateMap?.mode === 'absolute'} />
            <Typography>{i18n.t(keys.rateMapAbsoluteZones)}</Typography>
          </Stack>
        </ZoneContainer>
        <ZoneContainer
          selected={rateMap?.mode === 'relative'}
          onClick={() => handleChangeMode('relative')}
        >
          <Stack direction={'row'} spacing={2} alignItems="center">
            <Radio checked={rateMap?.mode === 'relative'} />
            <Typography>{i18n.t(keys.rateMapRelativeZones)}</Typography>
          </Stack>
        </ZoneContainer>
      </Stack>
      <Collapse in={rateMap && rateMap.mode === 'relative'}>
        <SliderContainer>
          <Stack spacing={1}>
            <Typography>
              {`${i18n.t(keys.visualization.numberOfGroups)}: ${
                rateMap?.modeConfiguration?.classes ?? 5
              }`}
            </Typography>
            <StyledSlider
              value={rateMap?.modeConfiguration?.classes ?? 5}
              step={1}
              max={10}
              min={0}
              onChange={(ev, value) => handleChangeZones(value as number)}
            />
            <Typography variant="subtitle1">
              {i18n.t(keys.visualization.groupBy)}
            </Typography>
            <Select
              sx={{
                padding: theme.spacing(1 / 16),
                backgroundColor: '#00000033',
                [`&:not(.Mui-focused,:hover)`]: {
                  [`& .MuiOutlinedInput-notchedOutline`]: {
                    border: 'none',
                  },
                },
              }}
              variant="outlined"
              value={rateMap?.modeConfiguration?.classifyBy ?? 'equal-areas'}
              onChange={(ev) =>
                handleChangeClassifyBy(
                  ev.target.value as 'equal-areas' | 'value-ranges'
                )
              }
            >
              <MenuItem value="equal-areas">
                {i18n.t(keys.visualization.equalAreas)}
              </MenuItem>
              <MenuItem value="value-ranges">
                {i18n.t(keys.visualization.valueRanges)}
              </MenuItem>
            </Select>
          </Stack>
        </SliderContainer>
      </Collapse>
      {/* <Button variant="contained" sx={{ width: '100%' }} color="inherit">
        <Stack
          direction={'row'}
          spacing={2}
          alignItems="center"
          justifyContent="center"
          gap={1}
          sx={{ width: '100%' }}
        >
          Create Custom Zones
          <Add />
        </Stack>
      </Button> */}
    </Stack>
  )
}
