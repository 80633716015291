import * as React from 'react'
import { Box, InputLabel, Stack, useTheme, InputAdornment } from '@mui/material'
import CurrencyInput from 'react-currency-input-field'

export const RateMapNumberInput = ({
  label,
  value,
  onChange,
  id,
  currency,
  endAdornment,
}: {
  currency?: boolean
  id?: string
  onChange: (value: string) => void
  value?: string
  label?: string
  endAdornment?: string
}) => {
  const theme = useTheme()

  const decimalScale = React.useMemo(() => {
    if (currency) {
      return 2
    }
    return 0
  }, [currency])

  return (
    <Stack gap={1} sx={{ width: '100%', overflow: 'hidden' }}>
      {label && (
        <InputLabel
          htmlFor={id}
          sx={{ textTransform: 'uppercase', fontSize: '10px' }}
        >
          {label}
        </InputLabel>
      )}
      <Box
        component="div"
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          py: 1 / 4,
          px: 1,
          borderRadius: '8px',
          color: theme.palette.text.primary,
          background: 'transparent',
          border: `1px solid #8C8A88`,
          minHeight: 10,
          height: 24,

          '&:hover': {
            borderColor: theme.palette.primary.main,
          },

          '&:focus-visible': {
            outline: 0,
          },
        }}
      >
        <CurrencyInput
          decimalScale={decimalScale}
          style={
            {
              verticalAlign: 'middle',
              margin: 0,
              width: '100%',
              border: 'none',
              background: 'transparent',
              borderRadius: 'inherit',
              outline: 0,
              color: 'inherit',
              fontWeight: '400',
              lineHeight: '15px',
              letterSpacing: '0.4px',
              textAlign: 'left',
              textWrap: 'nowrap',
              textOverflow: 'ellipsis',
              fontSize: '12px',
              '&:focus': {
                borderColor: theme.palette.primary.main,
              },
            } as any
          }
          id={id}
          value={value}
          onValueChange={(val) => onChange(val ?? '')}
        />
        {currency && endAdornment && (
          <InputAdornment position="end">{endAdornment}</InputAdornment>
        )}
      </Box>
    </Stack>
  )
}
