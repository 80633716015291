import { createSelector } from 'reselect'
import { selectRateMapFlightDate } from './selectRateMapFlightDate'
import { selectRateMapSelectedParcelIds } from './selectRateMapSelectedParcelIds'
import { RateMapContextState } from '../editor/RateMapContext'
import { ParcelData } from '../../../data/selectOrgMapData'
import { rateMapSelectedProductIds } from '../constants'

const getParcelsByIds = (
  _: RateMapContextState,
  parcelsById: Record<string, ParcelData>
) => parcelsById

export const selectRateMapUserSelection = createSelector(
  selectRateMapFlightDate,
  selectRateMapSelectedParcelIds,
  getParcelsByIds,
  (flightDate, selectedParcelIds, parcelsById) => {
    const selectedGroupId = selectedParcelIds?.[0]
      ? parcelsById[selectedParcelIds[0]]?.groupId
      : undefined

    return {
      selectedFlightDate: flightDate,
      selectedGroupId,
      selectedParcelIds,
      selectedProductIds: rateMapSelectedProductIds,
    }
  }
)
