import * as React from 'react'
import { Parcel, RateMap } from '../../../graphql/types'
import Exporter from '../../../hooks/useExportable/formats/formatters'
import {
  DownloadFunction,
  useExportable,
} from '../../../hooks/useExportable/useExportable'
import { useMap } from '../../../map/withMap'
import { useRateMapContext } from '../editor/RateMapContext'
import { pureVineZonesLayerID, vigorZonesLayerID } from '../constants'
import { selectSelectedMapSources } from '../../../data/selectSelectedMapSources'
import { useRedux } from '../../../hooks/useRedux'
import { useGenerateAmendmentZones } from './useGenerateAmendmentZones'
import { selectPreferredLanguage } from '../../../data/selectPreferredLanguage'
import { selectSelectedLegendProductId } from '../../../data/selectSelectedLegendProductId'
import { selectConvertedSelectedColorProfiles } from '../../../ProductStats/selectors/stats'
import { selectActiveProductSettings } from '../../../ProductSettings/store/selectors/selectActiveProductSettings'
import {
  feature,
  Feature,
  featureCollection,
  MultiPolygon,
  Polygon,
} from '@turf/helpers'
import { MapboxGeoJSONFeature } from 'mapbox-gl'
import { unionMany } from '../../../util/unionMany'
import i18n, { keys } from '../../../i18n'
import area from './../../../util/units/area'

const eviPropertyName = 'EVI'
const areaPropertyName = 'Area'

export const useRateMapExportable = (): [DownloadFunction] => {
  const [state] = useRedux()
  const { rateMap, amendmentTypes } = useRateMapContext()
  const { map } = useMap()
  const [rateMapAmendments] = useGenerateAmendmentZones()
  const preferredLanguage = selectPreferredLanguage(state)
  const productId = selectSelectedLegendProductId(state)
  const selectedColorProfiles =
    selectConvertedSelectedColorProfiles(state)[productId]
  const settings = selectActiveProductSettings(state)

  const selectedMapSources = selectSelectedMapSources(state)

  const rateMapMapSources = React.useMemo(() => {
    const pureVineZoneSources = selectedMapSources?.[pureVineZonesLayerID]
    const vigorZoneSources = selectedMapSources?.[vigorZonesLayerID]
    return [
      ...(pureVineZoneSources ? pureVineZoneSources : []),
      ...(vigorZoneSources ? vigorZoneSources : []),
    ]
  }, [selectedMapSources])

  const getRateMapZoneFeaturesByParcelIdAndStopIndex = React.useCallback(() => {
    if (!map) {
      return {}
    }
    const features = map.queryRenderedFeatures(undefined, {
      layers: rateMapMapSources.reduce(
        (acc, rms) => [...acc, ...rms.mapLayers.map(({ id }) => `${id}-layer`)],
        []
      ),
    })
    const filteredFeatures = features.filter((f) =>
      rateMapMapSources.some((source) => source.id === f.sourceLayer)
    )
    if (!filteredFeatures) {
      return {}
    }
    const colorProfile =
      selectedColorProfiles &&
      (selectedColorProfiles[settings.visualization!] ||
        selectedColorProfiles.absolute ||
        selectedColorProfiles.relative ||
        selectedColorProfiles.threshold)

    const dataStops = colorProfile?.dataStops ?? []

    const filteredFeaturesByParcelIdAndStopIndex = filteredFeatures.reduce(
      (acc, feature) => {
        const parcelId = rateMapMapSources.find(
          (v) => v.id === feature.sourceLayer
        )?.parcelId
        const stopIndex = dataStops.findIndex((stop, i) => {
          if (i === dataStops.length - 1) {
            return feature.properties?.[eviPropertyName] >= Number(stop[0])
          }
          const lessThanNextStop =
            feature.properties?.[eviPropertyName] < Number(dataStops[i + 1][0])
          const greaterThanCurrentStop =
            feature.properties?.[eviPropertyName] >= Number(stop[0])
          return greaterThanCurrentStop && lessThanNextStop
        })
        if (parcelId && stopIndex > -1) {
          if (!acc[parcelId]) {
            acc[parcelId] = {}
          }
          if (!acc[parcelId][stopIndex]) {
            acc[parcelId][stopIndex] = []
          }
          acc[parcelId][stopIndex].push(feature)
        } else {
          console.warn('No parcelId or stopIndex found for feature', feature)
        }
        return acc
      },
      {} as Record<string, Record<string, MapboxGeoJSONFeature[]>>
    )

    return filteredFeaturesByParcelIdAndStopIndex
  }, [map, rateMapMapSources, selectedColorProfiles, settings.visualization])

  const formatCsv = React.useCallback(
    (
      rateMap: RateMap,
      parcelSelection: Parcel[],
      rateMapZoneFeaturesByParcelIdAndStopIndex: Record<
        string,
        Record<string, MapboxGeoJSONFeature[]>
      >
    ) => {
      if (!rateMapAmendments) {
        return []
      }

      const data: any[] = []

      const total: any = {
        [i18n.t(keys.stats.block)]: 'Total',
        EVI_Class: '',
        Area_Ac: 0,
        Area_Ha: 0,
        [i18n.t(keys.rateMapAmendmentTypeTitle)]:
          amendmentTypes[rateMap?.amendmentTypeId ?? '']?.name?.[
            preferredLanguage
          ] ??
          amendmentTypes[rateMap?.amendmentTypeId ?? '']?.name.en ??
          '',

        [i18n.t(keys.rateMapRate)]: '',
        [i18n.t(keys.rateMapAmount)]: 0,
      }

      for (const parcel of parcelSelection) {
        for (const amendment of rateMapAmendments) {
          const zoneInfo =
            rateMapZoneFeaturesByParcelIdAndStopIndex[parcel.id]?.[
              amendment.stopIndex
            ]
          if (!zoneInfo || zoneInfo.length === 0) {
            continue
          }
          const zoneArea = zoneInfo?.reduce(
            (acc, zone) =>
              acc + Number(zone.properties?.[areaPropertyName] ?? 0),
            0
          )
          const amount =
            (amendment.rateOfApplication ?? 0) *
            zoneArea *
            Number(rateMap?.unitCost ?? 0)
          const row: any = {
            [i18n.t(keys.stats.block)]: parcel.name,
            EVI_Class: amendment.zoneId,
            Area_Ac: area.convert(
              zoneArea,
              area.units.hectare,
              area.units.acre,
              'acre'
            ).value,
            Area_Ha: zoneArea,
            [i18n.t(keys.rateMapAmendmentTypeTitle)]:
              amendmentTypes[rateMap?.amendmentTypeId ?? '']?.name?.[
                preferredLanguage
              ] ??
              amendmentTypes[rateMap?.amendmentTypeId ?? '']?.name.en ??
              '',
            [i18n.t(keys.rateMapRate)]: amendment.rateOfApplication,
            [i18n.t(keys.rateMapAmount)]: amount,
          }
          data.push(row)

          total.Area_Ac += row.Area_Ac
          total.Area_Ha += row.Area_Ha
          total[i18n.t(keys.rateMapAmount)] +=
            row?.[i18n.t(keys.rateMapAmount)] ?? 0
        }
      }

      data.push(total)

      return data
    },
    [amendmentTypes, preferredLanguage, rateMapAmendments]
  )

  const formatGeoJson = React.useCallback(
    (
      rateMap: RateMap,
      parcelSelection: Parcel[],
      rateMapZoneFeaturesByParcelIdAndStopIndex: Record<
        string,
        Record<string, MapboxGeoJSONFeature[]>
      >
    ) => {
      if (!rateMapZoneFeaturesByParcelIdAndStopIndex) {
        return featureCollection([])
      }

      const features: Feature<Polygon | MultiPolygon>[] = []

      for (const parcel of parcelSelection) {
        for (const amendment of rateMapAmendments) {
          const zoneInfo = rateMapZoneFeaturesByParcelIdAndStopIndex[
            parcel.id
          ]?.[amendment.stopIndex] as Feature<Polygon>[]
          if (!zoneInfo || zoneInfo.length === 0) {
            continue
          }
          const eviTotal = zoneInfo.reduce(
            (acc, zone) =>
              acc + Number(zone.properties?.[eviPropertyName] ?? 0),
            0
          )
          const EVImean = eviTotal / zoneInfo.length

          const zoneArea = zoneInfo?.reduce(
            (acc, zone) =>
              acc + Number(zone.properties?.[areaPropertyName] ?? 0),
            0
          )
          const amount =
            (amendment.rateOfApplication ?? 0) *
            zoneArea *
            Number(rateMap?.unitCost ?? 0)

          const multiPolygon = unionMany(zoneInfo)

          if (multiPolygon) {
            features.push(
              feature(multiPolygon.geometry, {
                EVImean,
                EVI_Class: amendment.zoneId,
                Area_Ac: area.convert(
                  zoneArea,
                  area.units.hectare,
                  area.units.acre,
                  'acre'
                ).value,
                Area_Ha: zoneArea,
                [i18n.t(keys.rateMapAmendmentTypeTitle)]:
                  amendmentTypes[rateMap?.amendmentTypeId ?? '']?.name?.[
                    preferredLanguage
                  ] ??
                  amendmentTypes[rateMap?.amendmentTypeId ?? '']?.name.en ??
                  '',
                [i18n.t(keys.rateMapRate)]: amendment.rateOfApplication,
                [i18n.t(keys.rateMapAmount)]: amount,
              })
            )
          }
        }
      }
      return featureCollection(features)
    },
    [amendmentTypes, preferredLanguage, rateMapAmendments]
  )

  const [download] = useExportable<RateMap>({
    data: rateMap ?? ({} as RateMap),
    formatters: [
      Exporter.exportCsv(formatCsv, 'csv'),
      Exporter.exportGeoJson(formatGeoJson, 'geojson'),
      Exporter.exportShapefile(formatGeoJson, 'shapefile'),
    ],
  })

  const downloadWithArgs = React.useCallback(
    (formatName: string, downloadName: string, parcelSelection: Parcel[]) => {
      const rateMapZoneFeaturesByParcelIdAndStopIndex =
        getRateMapZoneFeaturesByParcelIdAndStopIndex()
      download(
        formatName,
        downloadName,
        parcelSelection,
        rateMapZoneFeaturesByParcelIdAndStopIndex
      )
    },
    [download, getRateMapZoneFeaturesByParcelIdAndStopIndex]
  )

  return [downloadWithArgs]
}
