import { VisualizationMode } from '../ProductSettings/store/types'
import { AreaUnit } from '../util/units/area'
import { LengthUnit } from '../util/units/length'
import { NoteFormV2, OldParcelMeta, ParcelMeta } from '../vvapi/models'
import { NotificationBody } from '../redux/notifications/types'
import { UnitSystem } from '../util/units/unitSystem'

// Translated
export type Languages = 'en' | 'fr'

export type Translated = Partial<Record<Languages, string>>
export type ByUnitSystem<T> = Partial<Record<UnitSystem, T>>
// ? is this more of a shrug type?
export type Translation = Translated | string

// Base Model
export interface Model<ID = string> {
  readonly id: ID
}

interface Timestamps {
  createdAt: string
  updatedAt: string
  deletedAt?: string
}

// Base UserCreated
interface UserCreated extends Timestamps {
  createdBy: string
  CreatedBy: User

  updatedBy: string
  UpdatedBy: User
}

// vv_variables
export interface Variable {
  key: string
  value: any
}

export type Variable_create_input = Variable
export type Variable_update_input = Variable

// vv_variables
export interface AdminRole {
  userId: string
  role: string

  User: User
}

export type AdminRole_create_input = Pick<AdminRole, 'userId' | 'role'>
export type AdminRole_update_input = AdminRole_create_input

// Package
export interface Package extends Model, UserCreated {
  name: Translation
  description?: Translation | null
  code: string

  Package_MapLayerDefs: PackageMapLayerDefs[]
}

export type Package_create_input = Pick<
  Package,
  'name' | 'description' | 'code'
>

export type Package_update_input = Partial<
  Pick<Package, 'name' | 'description' | 'code'>
>

export interface PackageMapLayerDefs {
  id: string
  MapLayerDef: MapLayerDef
  Package: Package
}

// Order
export interface Order extends Model, UserCreated, BelongsToOrganization {
  comment?: string | null
  quoteId?: string | null
  invoiceId?: string | null
  purchaseOrderId?: string | null
  priority?: number | null
  TargetDeliveries?: TargetDelivery[]
  migratedSubscriptionId?: number | null
}

export type Order_create_input = Pick<
  Order,
  | 'organizationId'
  | 'comment'
  | 'priority'
  | 'migratedSubscriptionId'
  | 'quoteId'
  | 'invoiceId'
  | 'purchaseOrderId'
>

export type Order_update_input = Partial<
  Pick<
    Order,
    | 'comment'
    | 'priority'
    | 'quoteId'
    | 'purchaseOrderId'
    | 'invoiceId'
    | 'migratedSubscriptionId'
  >
>

interface BelongsToOrder {
  orderId: Order['id']
  Order?: Order | null
}

// TargetDelivery
export interface TargetDelivery extends UserCreated, BelongsToOrder {
  date: string
  Delivery?: Delivery | null
  inhibitFlightNotice?: boolean
  TargetDelivery_Parcel_Packages?: TargetDelivery_Parcel_Package[]
}

export type TargetDelivery_create_input = Pick<
  TargetDelivery,
  'orderId' | 'date' | 'inhibitFlightNotice'
>

export type TargetDelivery_update_input = Partial<
  Pick<TargetDelivery, 'date' | 'inhibitFlightNotice'>
>

// tslint:disable-next-line: class-name
export interface TargetDelivery_Parcel_Package
  extends BelongsToOrder,
    BelongsToTargetDelivery,
    BelongsToParcel {
  package: string
  Package?: Package
  Delivery?: Delivery
}

interface BelongsToTargetDelivery {
  orderId: TargetDelivery['orderId']
  targetDeliveryDate: TargetDelivery['date']
  TargetDelivery?: TargetDelivery | null
}

// Delivery
export interface Delivery
  extends Model,
    UserCreated,
    BelongsToOrganization,
    BelongsToTargetDelivery,
    BelongsToOrder {
  comment?: string | null

  MapSources: MapSource[]
  Flights: Flight[]
  TargetDelivery: TargetDelivery
  View_DeliveryStatus: View_DeliveryStatus
}

export type Delivery_create_input = Pick<
  Delivery,
  'organizationId' | 'orderId' | 'targetDeliveryDate' | 'comment'
> &
  Partial<Pick<Delivery, 'Flights'>>

export type Delivery_update_input = Partial<Pick<Delivery, 'comment'>>

// View_DeliveryStatus View
// tslint:disable-next-line: class-name
export interface View_DeliveryStatus extends BelongsToDelivery {
  totalCount: number
  enabledCount: number
  fulfilledCount: number
  enabled: boolean
  fulfilled: boolean
}

// Flight
export interface Flight
  extends UserCreated,
    BelongsToOrganization,
    BelongsToDelivery {
  /** YYYY-MM-DD */
  date: string
  comment?: string | null

  Delivery: Delivery
  MapSources: MapSource[]

  DataSetFlights?: DataSetFlight[]
}

export type Flight_create_input = Pick<
  Flight,
  'organizationId' | 'deliveryId' | 'date' | 'comment' | 'DataSetFlights'
>

export type Flight_update_input = Partial<
  Pick<Flight, 'date' | 'comment' | 'DataSetFlights'>
>

export interface DeliveryGroup
  extends UserCreated,
    BelongsToOrganization,
    BelongsToDelivery,
    BelongsToOrganizationGroup {
  name: string
  downloadStatus: 'missing' | 'finished' | 'error' | 'pending'
  DeliveryParcels: DeliveryParcel[]
}

export type DeliveryGroup_create_input = Partial<DeliveryGroup>

export type DeliveryGroup_update_input = Partial<DeliveryGroup>

interface BelongsToDeliveryGroup extends BelongsToOrganizationGroup {
  deliveryId: Delivery['id']
  DeliveryGroup?: DeliveryGroup | null
}

export interface DeliveryParcel
  extends UserCreated,
    BelongsToParcel,
    BelongsToDelivery,
    BelongsToDeliveryGroup,
    BelongsToOrganization {
  geometry: GeoJSON.Polygon | GeoJSON.MultiPolygon
  meta?: Record<string, any> | null
  name: string
  DeliveryParcelFiles?: DeliveryParcelFile[]
}

export interface TargetDelivery_geojson {
  parcelId?: number
  packages?: string[]
}

export type DeliveryParcel_create_input = Partial<DeliveryParcel>

export type DeliveryParcel_update_input = Partial<DeliveryParcel>

export interface DeliveryProcGroup
  extends BelongsToDelivery,
    BelongsToOrganization,
    Watchable {
  procGroup: string
  groupName: string
  location: string
  flightDate: Flight['date']
  qaStatus: 'not-reviewed' | 'failed' | 'complete'
  katoLineQAJobId?: string
  KatoLineQAJob?: Job
  LineDrawingJob?: Job
  ReferenceSetupJob?: Job
  ReferenceUpdateJob?: Job
  UploadJob?: Job
  Packages?: DeliveryProcGroupPackage[]
  HistoricalProcGroups?: DeliveryProcGroup[]
  TargetDelivery_geojsons?: TargetDelivery_geojson
  Assignee?: User
}

export type DeliveryProcGroup_create_input = Partial<DeliveryProcGroup>

export type DeliveryProcGroup_update_input = Partial<DeliveryProcGroup>

export interface DeliveryProcGroupPackage
  extends BelongsToDelivery,
    BelongsToOrganization {
  procGroup: string
  groupName: string
  package: string
  flightDate: Flight['date']
  Job?: Job
}

export type DeliveryProcGroupPackage_create_input = Partial<DeliveryProcGroup>

export type DeliveryProcGroupPackage_update_input = Partial<DeliveryProcGroup>

interface BelongsToDeliveryParcel extends BelongsToParcel {
  deliveryId: Delivery['id']
  DeliveryParcel?: DeliveryParcel | null
}

interface BelongsToDelivery {
  deliveryId: Delivery['id']
  Delivery?: Delivery | null
}

interface BelongsToFlight {
  deliveryId: Delivery['id']
  flightDate: Flight['date']
  Flight?: Flight | null
}

export interface DeliveryParcelFile
  extends Model,
    UserCreated,
    BelongsToDeliveryParcel,
    BelongsToFlight {
  filename: string
  url: string
  enabled: boolean
  uploadedAt: string
  MapSource?: MapSource
}

export type DeliveryParcelFile_create_input = Partial<DeliveryParcelFile>
export type DeliveryParcelFile_update_input = Partial<DeliveryParcelFile>

export interface DeliveryGroupFile
  extends Model,
    UserCreated,
    BelongsToDeliveryGroup,
    BelongsToDelivery,
    BelongsToOrganization {
  filename: string
  url: string
  enabled: boolean
  uploadedAt: string
  status: string
  file: { downloadToken: string }
}

export type DeliveryGroupFile_create_input = Partial<DeliveryGroupFile>
export type DeliveryGroupFile_update_input = Partial<DeliveryGroupFile>

export interface JobAttempt {
  attempt: number
  worker: string
  logLocation: string
  status: 'running' | 'complete' | 'error' | 'timeout' | 'cancelled'
  hasWarnings: boolean
  startedAt: string
  finishedAt: string
  output: Record<string, any>
  warnings: {
    id: string
    data: Record<string, any>
  }[]
}

export interface JobDepdendency {
  Job: Job
  DependsOnJob: Job
}

export interface JobStatus {
  status:
    | 'created'
    | 'cancelled'
    | 'complete'
    | 'error'
    | 'running'
    | 'timeout'
    | 'pending'
    | 'queued'
}

// Job
export interface Job extends Model {
  pipelineId: string
  pipeline: Pipeline
  taskName: string
  priority: number
  jobAttempts: JobAttempt[]
  Status?: JobStatus
  LatestJobAttempt?: JobAttempt
  Dependents?: JobDepdendency[]
  Dependencies?: JobDepdendency[]
  input: Record<string, any>
  isPending: boolean
  tags: string[]
}

export type Job_create_input = Pick<Job, 'taskName' | 'input'>

export type Job_update_input = Partial<Pick<Job, 'taskName'>>

// MapColorProfile
export interface ColorProfileLabel {
  label: Translation
  acronym?: Translation | null
}

export interface MapColorProfile
  extends Model,
    UserCreated,
    BelongsToOrganization,
    BelongsToMapLayerDef {
  type: 'value' | 'class'
  name: Translated
  description?: Translated
  display: 'step' | 'continuous' | 'class'
  dataStops: [string | number, string][]
  noDataColor?: string | null
  coverageColor?: string | null
  minLabel?: ColorProfileLabel | null
  maxLabel?: ColorProfileLabel | null
  noDataLabel?: ColorProfileLabel | null
  coverageLabel?: ColorProfileLabel | null

  MapColorProfileVisualizations: (MapColorProfileVisualization | undefined)[]
}

export type MapColorProfile_create_input = Pick<
  MapColorProfile,
  | 'organizationId'
  | 'mapLayerDefId'
  | 'type'
  | 'name'
  | 'description'
  | 'display'
  | 'dataStops'
  | 'noDataColor'
  | 'coverageColor'
  | 'minLabel'
  | 'maxLabel'
  | 'noDataLabel'
  | 'coverageLabel'
>

export type MapColorProfile_update_input = Partial<
  Pick<
    MapColorProfile,
    | 'type'
    | 'name'
    | 'description'
    | 'display'
    | 'dataStops'
    | 'noDataColor'
    | 'coverageColor'
    | 'minLabel'
    | 'maxLabel'
    | 'noDataLabel'
    | 'coverageLabel'
  >
>

interface BelongsToColorProfile {
  mapColorProfileId: MapColorProfile['id']
  MapColorProfile?: MapColorProfile | null
}

// MapColorProfileVisualization
export interface MapColorProfileVisualization
  extends Model,
    UserCreated,
    BelongsToColorProfile {
  visualization: VisualizationMode
  order: number
  default: boolean
}

export type MapColorProfileVisualization_create_input = Pick<
  MapColorProfileVisualization,
  'mapColorProfileId' | 'visualization' | 'order' | 'default'
>

export type MapColorProfileVisualization_update_input = Partial<
  Pick<MapColorProfileVisualization, 'visualization' | 'order' | 'default'>
>

// MapLayer
export interface MapLayer
  extends Model,
    UserCreated,
    BelongsToMapSource,
    BelongsToMapLayerDef,
    BelongsToOrganization,
    BelongsToDelivery {
  enabled: boolean
  mapSourceId: string
  mapLayerDefId: string
}

export type MapLayer_create_input = MapLayer

export type MapLayer_update_input = MapLayer

export interface MapLayerDefGroup extends Model, UserCreated, Timestamps {
  name: Translation
  order: number
}

export type MapLayerDefGroup_create_input = Pick<MapLayerDefGroup, 'name'>
export type MapLayerDefGroup_update_input = Pick<MapLayerDefGroup, 'name'>

// MapLayerDef
export interface MapLayerDef extends Model, UserCreated, BelongsToMapSourceDef {
  name: Translation
  description?: Translation
  dataProperty?: string
  noDataProperty?: string | null
  coverageProperty?: string | null
  weightProperty?: string | null
  order: number
  enabled: boolean
  pdfFilename: string | null
  pdfFilenameTranslations: Translation
  mapLayerDefGroupId: string | null
  layerGroupLabel: Translation

  Group?: MapLayerDefGroup | null

  DataProperty?: MapSourceProperty | null
  NoDataProperty?: MapSourceProperty | null
  CoverageProperty?: MapSourceProperty | null
  WeightProperty?: MapSourceProperty | null

  MapColorProfiles: MapColorProfile[]

  MapLayerSettings: (MapLayerSettings | undefined)[]
}

export interface MapLayerDef extends Model, UserCreated, BelongsToMapSourceDef {
  name: Translation
  description?: Translation
  dataProperty?: string
  noDataProperty?: string | null
  coverageProperty?: string | null
  weightProperty?: string | null
  order: number
  enabled: boolean
  pdfFilename: string | null
  pdfFilenameTranslations: Translation
  mapLayerDefGroupId: string | null
  layerGroupLabel: Translation

  DataProperty?: MapSourceProperty | null
  NoDataProperty?: MapSourceProperty | null
  CoverageProperty?: MapSourceProperty | null
  WeightProperty?: MapSourceProperty | null

  MapColorProfiles: MapColorProfile[]

  MapLayerSettings: (MapLayerSettings | undefined)[]
}

export type MapLayerDef_create_input = Pick<
  MapLayerDef,
  | 'mapSourceDefId'
  | 'name'
  | 'description'
  | 'dataProperty'
  | 'noDataProperty'
  | 'coverageProperty'
  | 'weightProperty'
  | 'order'
  | 'enabled'
>

export type MapLayerDef_update_input = Partial<
  Pick<
    MapLayerDef,
    | 'name'
    | 'description'
    | 'dataProperty'
    | 'noDataProperty'
    | 'coverageProperty'
    | 'weightProperty'
    | 'order'
    | 'enabled'
    | 'mapLayerDefGroupId'
  >
>

interface BelongsToMapLayerDef {
  mapLayerDefId: MapLayerDef['id']
  MapLayerDef?: MapLayerDef | null
}

export interface MapBoxSource {
  type?: string | null
  scheme?: string | null
  attribution?: string | null
  tileSize?: number | null
  minzoom?: number | null
  maxzoom?: number | null
  bounds?: string // box2d 'BOX(<minX minY,maxX maxY>)'|null
  tiles?: string[] | null
}

// MapSource
export interface MapSource
  extends Model,
    UserCreated,
    BelongsToDelivery,
    BelongsToDeliveryParcel,
    BelongsToFlight,
    BelongsToMapSourceDef,
    BelongsToOrganization,
    MapBoxSource {
  filename: string
  url: string
  meta?: Record<string, any>
  statsData: Record<string, any>
  properties: string[]
  MapLayers?: MapLayer[]
}

export type MapSource_create_input = Pick<
  MapSource,
  | 'mapSourceDefId'
  | 'deliveryId'
  | 'parcelId'
  | 'flightDate'
  | 'filename'
  | 'url'
  | 'meta'
>

export type MapSource_update_input = Partial<Pick<MapSource, 'url' | 'meta'>>

interface BelongsToMapSource {
  mapSourceId: MapSource['id']
  MapSource?: MapSource | null
}

export type SourceTypes =
  | 'point'
  | 'line'
  | 'zone'
  | 'field-bounds'
  | 'raster'
  | 'raster-background'

// MapSourceDef
export interface MapSourceDef
  extends Model,
    UserCreated,
    BelongsToOrganization {
  name: Translation
  description: Translation
  type: SourceTypes
  layerGroup: string
  layerGroupLabel: Translation
  MapSources: MapSource[]
  MapSourceDefFilenames: MapSourceDefFilename[]
  MapSourceProperties: MapSourceProperty[]
  MapLayerDefs: MapLayerDef[]
}

export type MapSourceDef_create_input = Pick<
  MapSourceDef,
  'organizationId' | 'name' | 'description' | 'type'
>

export type MapSourceDef_update_input = Partial<
  Pick<MapSourceDef, 'name' | 'description' | 'type'>
>

export interface MapSourceDefFilename {
  filename: string
  mapSourceDefId: MapSourceDef['id']
  description: Translated
}

export type MapSourceDefFilename_create_input = Pick<
  MapSourceDefFilename,
  'filename' | 'mapSourceDefId' | 'description'
>

export type MapSourceDefFilename_update_input = Partial<
  Pick<MapSourceDefFilename, 'filename' | 'description'>
>

interface BelongsToMapSourceDef {
  mapSourceDefId: MapSourceDef['id']
  MapSourceDef?: MapSourceDef | null
}

// MapSourceProperty
export interface MapSourcePropertyClass {
  value: string | number
  translation: Translation
}

// TODO: what is 'count'?
export type SourcePropertyTypes = 'text' | 'index' | 'area' | 'length' | 'count'

export interface MapSourceProperty
  extends Model,
    UserCreated,
    BelongsToMapSourceDef {
  property: string
  type: string
  measurementType: 'absolute' | 'relative'
  name: Translation
  description?: Translation
  acronym?: Translation | null
  valueType?: SourcePropertyTypes | null
  // TODO: what is 'count'?
  valueUnit?: AreaUnit | LengthUnit | null | 'count'
  range?: [number, number] | null
  classes?: MapSourcePropertyClass[] | null
  noDataValue?: any | null
  filterable?: boolean | null
  showInPopup?: boolean | null
}

export type MapSourceProperty_create_input = Pick<
  MapSourceProperty,
  | 'mapSourceDefId'
  | 'property'
  | 'type'
  | 'name'
  | 'description'
  | 'acronym'
  | 'valueType'
  | 'valueUnit'
  | 'measurementType'
  | 'range'
  | 'classes'
  | 'noDataValue'
  | 'filterable'
  | 'showInPopup'
>

export type MapSourceProperty_update_input = Partial<
  Pick<
    MapSourceProperty,
    | 'property'
    | 'type'
    | 'name'
    | 'description'
    | 'acronym'
    | 'valueType'
    | 'valueUnit'
    | 'measurementType'
    | 'range'
    | 'classes'
    | 'noDataValue'
    | 'filterable'
    | 'showInPopup'
  >
>

// Organization
export interface Organization extends Model {
  name: string
  billingCountry?: string
  betaEnabled: boolean
}

export type Organization_create_input = Pick<
  Organization,
  'name' | 'billingCountry' | 'betaEnabled'
>

export type Organization_update_input = Partial<
  Pick<Organization, 'name' | 'billingCountry' | 'betaEnabled'>
>

export interface OrganizationGroup extends Model, BelongsToOrganization {
  name: string
  deletedAt?: string
}

interface BelongsToOrganizationGroup {
  groupId: OrganizationGroup['id']
  OrganizationGroup?: OrganizationGroup | null
}

export type OrganizationGroup_create_input = Pick<OrganizationGroup, 'name'>

export type OrganizationGroup_update_input = Partial<
  Pick<OrganizationGroup, 'name'>
>

interface BelongsToOrganization {
  organizationId?: Organization['id']
  Organization?: Organization | null
}

export interface Watchable {
  Watchers?: {
    User: {
      id: number
      email: string
      firstName: string
      lastName: string
    }
  }[]
}

// Parcel
export interface Parcel
  extends Model,
    BelongsToOrganization,
    BelongsToOrganizationGroup {
  name: string
  geometry: GeoJSON.Polygon | GeoJSON.MultiPolygon
  meta?: ParcelMeta & OldParcelMeta
  DeliveryParcels?: DeliveryParcel[]
}

export type Parcel_create_input = Pick<Parcel, 'name'>

export type Parcel_update_input = Partial<Pick<Parcel, 'name'>>

export interface ParcelDataSet extends Model<number> {
  name: string
  deliveryId: DeliveryParcel['deliveryId']
  parcelId: DeliveryParcel['parcelId']
  meta: DeliveryParcel['meta']
  geometry: DeliveryParcel['geometry']

  mapSources: MapSource[]

  Parcel: {
    name: Parcel['name']
    meta: Parcel['meta']
  }
}

export type ParcelDataSet_create_input = Pick<ParcelDataSet, 'name'>

export type ParcelDataSet_update_input = Partial<Pick<ParcelDataSet, 'name'>>

// Pipeline
export interface Pipeline extends Model {
  name: string
  isPaused: boolean
}

export type Pipeline_create_input = Pick<Pipeline, 'name' | 'isPaused'>

export type Pipeline_update_input = Partial<Pick<Pipeline, 'name' | 'isPaused'>>

interface BelongsToParcel {
  parcelId: Parcel['id']
  Parcel?: Parcel | null
}

// User
export interface User extends Model {
  email: string
  firstName: string
  lastName: string
}

export type User_create_input = Pick<User, 'email' | 'firstName' | 'lastName'>

export type User_update_input = Partial<
  Pick<User, 'email' | 'firstName' | 'lastName'>
>

// Subscriptions
export interface Subscription extends Model<number>, Timestamps {
  organizationId: string
  quoteId: string
  invoiceId: string
  purchaseOrderId: string
}
export type Subscription_create_input = Pick<
  Subscription,
  'id' | 'organizationId' | 'quoteId' | 'invoiceId' | 'purchaseOrderId'
>

export type Subscription_update_input = Partial<
  Pick<Subscription, 'quoteId' | 'invoiceId' | 'purchaseOrderId'>
>

interface CameraMeta {
  cameraId: number
  lensId: number
}

interface DataSetMeta extends Record<string, any> {
  cameras?: CameraMeta[]
}

export type VVRegion = 'California' | 'France'

export const DataSetPriorityList = ['LOW', 'MEDIUM', 'HIGH'] as const
export type DataSetPriority = typeof DataSetPriorityList[number]

export interface DataSet extends Model, Timestamps {
  deletedAt?: string

  acquisitionDate?: string
  geometry?: GeoJSON.Polygon
  region?: VVRegion
  meta?: DataSetMeta
  notes?: string
  status?: string
  uuid?: string
  priority?: DataSetPriority

  createdByUserId: number
  CreatedBy?: User | null

  DataSetImages?: DataSetImage[]
  DataSetFlights?: DataSetFlight[]
}

export type DataSet_create_input = Pick<
  DataSet,
  | 'acquisitionDate'
  | 'geometry'
  | 'meta'
  | 'notes'
  | 'region'
  | 'status'
  | 'uuid'
  | 'deletedAt'
  | 'priority'
>

export type DataSet_update_input = Partial<
  Pick<
    DataSet,
    | 'acquisitionDate'
    | 'geometry'
    | 'meta'
    | 'notes'
    | 'region'
    | 'status'
    | 'deletedAt'
    | 'priority'
  >
>
export interface DataSetFlight
  extends UserCreated,
    BelongsToFlight,
    BelongsToDataSet {}

interface BelongsToDataSet {
  dataSetId: DataSet['id']
  DataSet?: DataSet | null
}

export enum DataSetImageState {
  RawUploadStarted = 'RawUploadStarted',
  RawUploadFinished = 'RawUploadFinished',
  RawUploadError = 'RawUploadError',

  TifConversionStarted = 'TifConversionStarted',
  TifConversionFinished = 'TifConversionFinished',
  TifConversionError = 'TifConversionError',

  BandAlignmentStarted = 'BandAlignmentStarted',
  BandAlignmentFinished = 'BandAlignmentFinished',
  BandAlignmentError = 'BandAlignmentError',

  OrthoStarted = 'OrthoStarted',
  OrthoFinished = 'OrthoFinished',
  OrthoError = 'OrthoError',

  AtcorStarted = 'AtcorStarted',
  AtcorFinished = 'AtcorFinished',
  AtcorError = 'AtcorError',

  GeoreferenceStarted = 'GeoreferenceStarted',
  GeoreferenceFinished = 'GeoreferenceFinished',
  GeoreferenceError = 'GeoreferenceError',

  ApplyCorrectionsStarted = 'ApplyCorrectionsStarted',
  ApplyCorrectionsFinished = 'ApplyCorrectionsFinished',
  ApplyCorrectionsError = 'ApplyCorrectionsError',

  GeneratePreviewStarted = 'GeneratePreviewStarted',
  GeneratePreviewFinished = 'GeneratePreviewFinished',
  GeneratePreviewError = 'GeneratePreviewError',
}

export interface MonitorEvent extends Model {
  sendNotification: boolean
  tags: string[]
  eventTime: string
  eventData: Record<string, any>
  title: string
  level: 'log' | 'debug' | 'warn' | 'error' | 'fatal'
}

export type MonitorEvent_create_input = Partial<
  Pick<
    MonitorEvent,
    'sendNotification' | 'tags' | 'eventTime' | 'eventData' | 'title' | 'level'
  >
>

export type MonitorEvent_update_input = Partial<
  Pick<
    MonitorEvent,
    'sendNotification' | 'tags' | 'eventTime' | 'eventData' | 'title' | 'id'
  >
>

export interface DataSetImage extends Model, Timestamps, BelongsToDataSet {
  deletedAt?: string

  center: GeoJSON.Point
  bbox: GeoJSON.Polygon
  meta?: DataSetImageMeta
  filenames?: string[]
  altitude?: number
  gpsTime?: string
  elevation?: number
  yaw?: number
  pitch?: number
  roll?: number
  state?: DataSetImageState
  isCritical?: boolean
  log?: string
}

export interface DataSetImageMeta {
  originalFilenames?: string[]
  rawLogMatch?: {
    methodUsed: string
    discrepancy: string | number
    latDiff: string | number
    lngDiff: string | number
  }
  uploadMessage?: {
    type: string
    text: string
  }

  cloudWatch?: CloudWatchMeta[]
  tifConversion?: TifConversionMeta
}

export interface CloudWatchMeta {
  createdAt: string
  logGroupName: string
  logStreamName: string
}

export interface TifConversionMeta {
  createdAt: string
  files: TifConversionFileMeta[]
}

export interface TifConversionFileMeta {
  filename: string
  serialNumber: string
  cameraId: number
  lensId: number
  wavelengths: number[]
}

export type DataSetImage_create_input = Pick<
  DataSetImage,
  | 'deletedAt'
  | 'center'
  | 'bbox'
  | 'meta'
  | 'filenames'
  | 'altitude'
  | 'gpsTime'
  | 'elevation'
  | 'yaw'
  | 'pitch'
  | 'roll'
  | 'state'
  | 'isCritical'
  | 'log'
>

export type DataSetImage_update_input = Partial<
  Pick<
    DataSetImage,
    | 'deletedAt'
    | 'center'
    | 'bbox'
    | 'meta'
    | 'filenames'
    | 'altitude'
    | 'gpsTime'
    | 'elevation'
    | 'yaw'
    | 'pitch'
    | 'roll'
    | 'state'
    | 'isCritical'
    | 'log'
  >
>

export interface MapLayerSettings
  extends Model,
    UserCreated,
    BelongsToOrganization {
  visualization?: 'absolute' | 'relative' | 'threshold'
  threshold?: number
  opacity?: number
  numberOfGroups?: number
  groupBy?: 'value-ranges' | 'equal-areas'
  coverageMin?: number
  coverageMax?: number
  coverage?: number
  dotSize?: 'small' | 'medium' | 'large'
  mapLayerDefId: MapLayerDef['id']
}

export type MapLayerSettings_create_input = Pick<
  MapLayerSettings,
  | 'organizationId'
  | 'mapLayerDefId'
  | 'visualization'
  | 'threshold'
  | 'opacity'
  | 'numberOfGroups'
  | 'groupBy'
  | 'coverageMin'
  | 'coverageMax'
  | 'dotSize'
>

export type MapLayerSettings_update_input = Partial<
  Pick<
    MapLayerSettings,
    | 'visualization'
    | 'threshold'
    | 'opacity'
    | 'numberOfGroups'
    | 'groupBy'
    | 'coverageMin'
    | 'coverageMax'
    | 'dotSize'
  >
>

export const emailNotificationTypesArray = [
  'delivery|data-available',
  'delivery|flight-complete',
  'order|confirmation',
  'target-delivery|flight-notice',
] as const

export type EmailNotificationTypes = typeof emailNotificationTypesArray[number]

export const appNotificationTypesArray = [
  'app|generic-text',
  'advertise|mobile-app',
]

export type AppNotificationTypes = typeof appNotificationTypesArray[number]

export type NotificationTypes = EmailNotificationTypes | AppNotificationTypes

export interface ScheduledNotification extends Model, Timestamps {
  name: string
  desc: string
  sendDate: string
  expireDate: string
  type: NotificationTypes
  data: any
  organizationIds: number[]
  userIds: number[]
  sent: boolean
}

export type ScheduledNotification_create_input = Partial<
  Pick<
    ScheduledNotification,
    | 'name'
    | 'desc'
    | 'sendDate'
    | 'expireDate'
    | 'type'
    | 'data'
    | 'organizationIds'
    | 'userIds'
  >
>

export type ScheduledNotification_update_input = Partial<
  Pick<
    ScheduledNotification,
    | 'name'
    | 'desc'
    | 'sendDate'
    | 'expireDate'
    | 'type'
    | 'data'
    | 'organizationIds'
    | 'userIds'
  >
>

export interface EmailNotification {
  emailed: boolean
  emailedAt: string
  viewedEmailAt: string
}

export interface AppNotification {
  viewedAt: string
}

export interface Notification
  extends EmailNotification,
    AppNotification,
    Model,
    Timestamps {
  userId: number
  type: NotificationTypes
  data: NotificationBody
  scheduledNotificationId: number
}

export type Notification_create_input = Partial<
  Pick<Notification, 'userId' | 'type' | 'data'>
>

export type Notification_update_input = Partial<Pick<Notification, 'viewedAt'>>

export const notificationActionTypeArray = ['link-opened'] as const
export type NotificationActionType = typeof notificationActionTypeArray[number]

export const maestroCronTaskTypeArray = ['reprocess-cevi-a-tif'] as const
export type MaestroCronTaskType = typeof maestroCronTaskTypeArray[number]

export interface MaestroCronTask {
  taskName: MaestroCronTaskType
  organizationId: number
  enabled: boolean
  createdAt: string
}

export type MaestroCronTask_create_input = Pick<
  MaestroCronTask,
  'taskName' | 'organizationId' | 'enabled'
>

export type MaestroCronTask_update_input = Pick<MaestroCronTask, 'enabled'>

export interface Note extends Model, Timestamps {
  content: string
  featureId: string
  Feature?: Feature
}

export interface Feature extends Model, Timestamps {
  geometry: GeoJSON.Point | GeoJSON.Polygon | GeoJSON.MultiPolygon
  // meta: string
}

export interface Sample extends Model, Timestamps {
  samplePlanId: string
  SamplePlan: SamplePlan
  samplePlanBlockId: string
  SamplePlanBlock?: SamplePlanBlock
  completedAt?: string
  completedBy?: string
  CompletedByUser?: User
  geometry: GeoJSON.Point | GeoJSON.Polygon | GeoJSON.MultiPolygon
  content: Record<string, any>
  plantID: string
  rowID: string
  properties: Record<string, any>
  plantIndex: string
}

export interface Sample_aggregate {
  aggregate: {
    count: number
  }
}

export interface SamplePlanBlock extends Model {
  samplePlanId: string
  SamplePlan: SamplePlan
  parcelId: string
  Parcel: Parcel
  Samples: Sample[]
  totalSamples?: Sample_aggregate
  completedSamples?: Sample_aggregate
  generatedSampleFeatures: GeoJSON.FeatureCollection
  samplingStatisticalMethodId: string
  SamplingStatisticalMethod?: SamplingStatisticalMethod
  metadata: Record<string, any>
}

export interface SamplePlan extends Model, UserCreated {
  organizationId: number
  name: string
  isPending: boolean
  SamplePlanBlocks?: SamplePlanBlock[]
  Samples?: Sample[]
  totalSamples?: Sample_aggregate
  completedSamples?: Sample_aggregate
  formId: string
  NoteForm?: NoteFormV2
  templateName?: string
  projectId: string
  SamplePlanCreateDataSet?: SamplePlanCreateDataSet
  samplePlanCreateDataSetId?: string
}

export type SamplePlan_create_input = Pick<
  SamplePlan,
  'name' | 'organizationId' | 'createdBy' | 'formId'
>

export type SamplePlanBlock_create_input = Pick<
  SamplePlanBlock,
  'samplePlanId' | 'parcelId'
>

export interface SamplingType extends Model {
  name: Translation
}

export interface SamplingStatisticalMethod extends Model {
  name: Translation
  info: {
    sections: {
      title: Translation
      description: Translation
    }[]
    image: string
  }
  SamplingStatisticalMethodFields?: SamplingStatisticalMethodField[]
  SamplingStatisticalMethodDataSources?: SamplingStatisticalMethodDataSource[]
}

export interface SamplingStatisticalMethodField extends Model {
  name: Translation
  description: Translation
  required: boolean
  type: 'zones-per-block' | 'rows-per-block' | 'exclude-edge-vines' | 'cluster'
  defaultValue: string
  valueType: 'text' | 'int'
  order: number
}

export interface SamplingStatisticalMethodDataSource extends Model {
  name: Translation
  description: Translation
  required: boolean
  SamplingStatisticalMethodDataSourceFiles: SamplingStatisticalMethodDataSourceFile[]
}

export interface SamplingStatisticalMethodDataSourceFile extends Model {
  dataSourceId: string
  fileType: string
  sourceType: 'zone' | 'plant'
  priority: number
  required: boolean
}

export interface BlockSampleData {
  parcelId: string
  sampleCount: number
}

export interface Source {
  dataSourceFileId: string
  parcelId: string
  url: string
  sourceType: string
}

export interface DataSourceData {
  flightDate: string
  deliveryParcelUrls: Source[]
  required: boolean
}

export interface SamplePlanCreateDataSet {
  samplePlanName?: string
  NoteForm?: NoteFormV2
  noteFormTemplateId?: string
  templateName?: string
  projectInstructions?: string
  SamplingStatisticalMethod?: SamplingStatisticalMethod
  statisticalMethodTypeId?: string
  statisticalMethodFields?: any
  flightDate?: string
  statisticalMethodDataSources?: Record<string, DataSourceData>
  createByUserId?: string
  organizationId?: string
  selectedParcelIds?: string[]
  selectedParcels?: Parcel[]
  blockSamplesData?: BlockSampleData[]
  complete?: boolean
  samplePlanId?: string
  samplePlan?: SamplePlan
}

export interface FeatureFlagOrganizationAccess {
  featureFlagId: string
  organizationId: number
  Organization?: Organization
}

export interface FeatureFlagUserAccess {
  featureFlagId: string
  userId: number
  User?: User
}

export interface FeatureFlag extends Model {
  active: boolean
  name: string
  desc: string
  createdAt: string
  FeatureFlagUserAccesses?: FeatureFlagUserAccess[]
  FeatureFlagOrganizationAccesses?: FeatureFlagOrganizationAccess[]
}

export type ProjectType = 'notes' | 'sample-plan'

export interface Project extends Model, UserCreated {
  type: ProjectType
  organizationId: string
  name: string
  description: string
  bounds?: string
  Notes_aggregate: {
    aggregate: {
      count: number
    }
  }
}

export interface RateMapUnitType extends Model, UserCreated {
  organizationId: string
  name: ByUnitSystem<Translated>
  description: ByUnitSystem<Translated>
  defaultConfiguration: Record<string, any>
  weightUnit: ByUnitSystem<string>
  areaUnit: ByUnitSystem<string>
}

export interface RateMapCustomZoneType extends Model {
  name: Translation
  description: Translation
  defaultConfiguration: Record<string, any>
}

export interface RateMapAmendmentType extends Model, UserCreated {
  organizationId: string
  name: Translated
  defaultConfiguration: Record<string, any>
}

export interface RateMapAmendmentModeType extends Model {
  name: Translation
  description: Translation
  defaultConfiguration: Record<string, any>
}

export interface RateMap extends Model, UserCreated {
  name: string
  projectId: string
  organizationId: string
  deliveryId: string
  flightDate: string
  amendmentTypeId: string
  unitTypeId: string
  unitCost: string
  mode: string
  modeConfiguration: Record<string, any>
  duplicateOfId: string
  Mode?: RateMapAmendmentModeType
  DuplicateOf?: RateMap
  amendmentZoneRates: number[]
  RateMapBlockSelections: RateMapBlockSelection[]
  RateMapAdditionalLayers: RateMapAdditionalLayer[]
  RateMapBackgroundLayers: RateMapBackgroundLayer[]
  RateMapAmendmentType?: RateMapAmendmentType
  RateMapUnitType?: RateMapUnitType
  RateMapCustomZones: RateMapCustomZone[]
}

export interface RateMapCustomZone extends Model, UserCreated {
  rateMapId: string
  customZoneTypeId: string
  amendmentId: string
  parcelId: string
  geometry: GeoJSON.Polygon
  RateMap?: RateMap
  RateMapCustomZoneType?: RateMapCustomZoneType
  Parcel?: Parcel
}

export interface RateMapBlockSelection {
  rateMapId: string
  parcelId: string
  enabled: boolean
  RateMap?: RateMap
  Parcel?: Parcel
}

export interface RateMapAdditionalLayer {
  rateMapId: string
  layerId: string
  enabled: boolean
  opacity: number
  RateMap?: RateMap
  Layer?: MapLayer
}

export interface RateMapBackgroundLayer {
  rateMapId: string
  layerId: string
  enabled: boolean
  opacity: number
  RateMap?: RateMap
  Layer?: MapLayer
}
