import * as React from 'react'
import {
  Button,
  Icon,
  Menu,
  MenuItem,
  Stack,
  styled,
  Tooltip,
  Typography,
} from '@mui/material'
import i18n, { keys } from '../i18n'
import { Tool } from './types'
import {
  KeyboardArrowDown,
  KeyboardArrowUp,
  Settings,
} from '@mui/icons-material'
import { useFeatureFlag } from '../hooks/useFeatureFlag'

interface Props {
  tools?: Tool[]
  color?: string
}

const ToolMenu = styled(Menu)(({ theme }) => ({
  paddingTop: 0,
  paddingBottom: 0,
  marginLeft: theme.spacing(1),
}))

const ToolText = styled(Typography)(({ theme }) => ({
  fontSize: '16px',
  fontWeight: 500,
  lineHeight: '19.2px',
  letterSpacing: '-0.035em',
  textAlign: 'left',
}))

const ToolIcon = styled(Icon)(({ theme }) => ({
  width: '24px',
  height: '24px',
}))

export const ToolSelector = ({ tools, color }: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const openContext = Boolean(anchorEl)
  const { featureEnabled: rateMapEnabled } = useFeatureFlag({
    featureFlagId: 'rate-maps',
  })
  const { featureEnabled: samplePlanningEnabled } = useFeatureFlag({
    featureFlagId: 'sample-planning',
  })

  const filteredTools = React.useMemo(() => {
    return tools?.filter((tool) => {
      return (
        (tool.id === 'rate-maps' && rateMapEnabled) ||
        (tool.id === 'sample-planning' && samplePlanningEnabled)
      )
    })
  }, [tools, rateMapEnabled, samplePlanningEnabled])

  const handleOpenContext = (
    event: React.MouseEvent<HTMLElement>,
    ref: HTMLElement | null
  ) => {
    event.stopPropagation()
    event.preventDefault()
    setAnchorEl(ref)
  }
  const handleCloseContext = () => {
    setAnchorEl(null)
  }

  if (!filteredTools || filteredTools.length === 0) {
    return null
  }

  const onClickOption = (tool: Tool) => {
    tool.onClick()
    handleCloseContext()
  }

  return (
    <>
      <Button
        style={{ color }}
        onClick={(event) => handleOpenContext(event, event.currentTarget)}
        startIcon={<Settings />}
        endIcon={openContext ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
      >
        {i18n.t(keys.tools)}
      </Button>
      <ToolMenu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        onClose={handleCloseContext}
        open={openContext}
      >
        {filteredTools.map((tool) => (
          <MenuItem key={tool.id} onClick={() => onClickOption(tool)}>
            <Tooltip title={tool.description}>
              <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                justifyContent="space-between"
              >
                {typeof tool.icon === 'string' ? (
                  <ToolIcon>{tool.icon}</ToolIcon>
                ) : (
                  tool.icon
                )}
                <ToolText>{tool.name}</ToolText>
              </Stack>
            </Tooltip>
          </MenuItem>
        ))}
      </ToolMenu>
    </>
  )
}
